@import './../../../../css/variables';

.content {
  display: flex;
  li {
    display: inline-block;
    list-style-type: none;
    margin: 1.1rem 1.8rem;
    &:last-child {
      margin-right: 0;
    }
  }
  .list_button {
    color: $la-tasa-blue;
    font-size: 0.8rem;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    &:hover {
      text-decoration: underline;
      color: $la-tasa-light-blue;
    }
  }
  @media screen and (max-width: $md-breakpoint) {
    &.closed {
      display: none;
    }
    &.open {
      display: block;
      overflow: hidden;
      background: #fff;
      flex-direction: column;
      height: 100%;
      position: fixed;
      left: 0;
      text-align: center;
      top: $top-bar-height;
      width: 100vw;
      z-index: 999;
      li {
        margin: 1.1rem 0;
        width: 100%;
      }
    }
  }
}

.toggle {
  .toggle_icon {
    display: flex;
    overflow: hidden;
    &:before {
      font-size: 32px;
    }
  }
  color: $la-tasa-dark-blue;
  margin: auto 0;
  text-decoration: none;
  display: none;
  @media screen and (max-width: $md-breakpoint) {
    display: inherit;
  }
  &:hover {
    color: $la-tasa-light-blue;
  }
}
