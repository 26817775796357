@import '../../../css/variables';

.checkbox {
  box-sizing: border-box;
  display: block;
  position: relative;
  padding-left: 1.5rem;
  user-select: none;
  /* hide default checkbox */
  input {
    cursor: pointer;
    position: absolute;
    opacity: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    cursor: pointer;
    position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px;
    border: 1px solid $border-color;
    border-radius: 2px;
    background-color: $white;
    /* Create the checkmark/indicator (hidden when not checked) */
    &:after {
      content: '';
      position: absolute;
      display: none;
    }
    &.checked {
      &:after {
        display: block;
        left: 6px;
        top: -3px;
        width: 5px;
        height: 12px;
        border: solid $sky-blue;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }
  }
}
