@import './../../../css/variables';

.option_switch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .group {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
