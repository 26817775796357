@import './../variables';

.clickable {
  cursor: pointer;
  user-select: none;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.inline-direction {
  display: inline-flex;
  width: 100%;
  & > * {
    &:not(:first-child) {
      margin-left: $margin_small / 2;
    }
    &:not(:last-child) {
      margin-right: $margin_small / 2;
    }
  }
}
