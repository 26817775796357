@import './../../../css/variables';

.input {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  position: relative;

  .input_container {
    align-items: center;
    display: flex;
    width: 100%;
    input {
      background: white;
      box-sizing: border-box;
      border-radius: 4px;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      height: 40px;
      width: 100%;
      color: $orient-blue;
      line-height: 1.5rem;
      font-size: 0.875rem;
      border: 1px solid $light-gray;
      &.invalid,
      &.invalid:focus {
        border: 2px solid $error;
      }
      &::placeholder {
        color: $light-gray;
      }
      &:disabled {
        color: $light-gray;
        background-color: $disabled;
      }
    }
  }
  .label {
    font-weight: normal;
    color: $la-tasa-dark-blue;
    text-transform: uppercase;
  }
  .label_container {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
}

.input_icon {
  line-height: 1.125rem;
  padding-right: 0.5rem;
  position: absolute;
  right: 5px;
}
