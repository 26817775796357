@import './../../../../css/variables';

.wizard_stepper {
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.stepper_steps {
  display: flex;
  margin: 0 auto;
  height: 25px;
  width: 65%;
  @media screen and (max-width: $md-breakpoint) {
    width: 80%;
  }
}

.stepper_labels {
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  width: 75%;
  @media screen and (max-width: $md-breakpoint) {
    width: 100%;
  }
}

.outer_border {
  width: 25px;
  height: 25px;
  border: 2px solid lighten($color: $celeste, $amount: 30);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  z-index: 2;
  background-color: $background_gray;
  &.completed {
    border: 0;
    width: 19px;
  }
}

.inner_border {
  width: 19px;
  height: 19px;
  border: 2px solid $celeste;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  span {
    width: 100%;
    text-align: center;
  }
  &.completed {
    border: 2px solid $la-tasa-dark-blue;
    span {
      &::before {
        font-weight: bolder;
      }
    }
  }
}

.wizard_step {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  &:not(:last-child) {
    width: 90%;
    &:after {
      background: $light-gray;
      content: '';
      display: block;
      height: 2px;
      position: relative;
      width: 100%;
      z-index: 1;
    }
  }

  &.completed {
    &:after {
      background: $la-tasa-dark-blue;
    }
  }

  .step_number {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
  }

  .inactive_mark {
    background-color: $light-gray;
    border-radius: 5px;
    height: 10px;
    width: 10px;
  }
}

.active {
  color: $celeste;
  font-weight: bold;
}

.inactive:last-child .step_number {
  margin-right: 15px;
}

.inactive {
  color: $light-gray;
}
.completed {
  color: $la-tasa-dark-blue;
}
