@import '../../../css/variables';

.file_uploader {
  min-width: 50px;
  min-height: 40px;
  border: 1px solid $light-gray;
  border-radius: 4px;
  background-color: $white;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $margin_small;
  &.selected {
    box-shadow: $la-tasa-light-blue 0px 0px 0px 2pt;
  }
  &.invalid,
  &.invalid:focus {
    border: 2px solid $error;
  }
  cursor: pointer;
}
