@import './../../../css/variables';

.select {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  position: relative;
  &_toggle {
    right: 11px;
    position: absolute;
    pointer-events: none;
  }
  .select_container {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 100%;
    .select_field {
      border-radius: 4px;
      appearance: none;
      background: white;
      box-sizing: border-box;
      border-radius: 4px;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      height: 40px;
      width: 100%;
      line-height: 1.5rem;
      font-size: 0.875rem;
      border: 1px solid $light-gray;
      &:disabled {
        color: $light-gray;
        background-color: $disabled;
      }
      &.invalid,
      &.invalid:focus {
        border: 2px solid $error;
      }
    }
  }
  .label {
    font-weight: normal;
    color: $la-tasa-dark-blue;
    text-transform: uppercase;
  }
  .label_container {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
}
