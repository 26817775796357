@import '../../../css/variables';

.button {
  background-color: transparent;
  &:focus {
    text-decoration: underline;
  }
  &.primary {
    border-radius: 1.25rem;
    font-size: 1rem;
    line-height: 1.375rem;
    padding: 0.5625rem;
    text-transform: uppercase;
    width: 100%;
    background-color: $sky-blue;
    color: $white;
    &:hover {
      text-decoration: underline;
    }
  }
  &.back-button {
    color: $light-gray;
    font-size: 1rem;
    line-height: 1.375rem;
    text-transform: uppercase;
    font-weight: 600;
    &::before {
      content: '\F13D ';
      font-family: 'Material Design Icons';
      &:hover {
        text-decoration: none;
      }
    }
    &:hover {
      text-decoration: underline;
    }
  }
  &.link {
    text-decoration: underline;
    color: $celeste;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.88rem;
  }
  &[disabled] {
    background-color: $disabled;
    color: $light-gray;
    pointer-events: none;
  }
}
