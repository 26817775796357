@import './../../../../../../css/variables';

$base-color: $la-tasa-light-blue;

.quantity_button {
  width: 32px;
  height: 32px;
  user-select: none;
  cursor: pointer;
  color: $white;
}
.circle_simulator {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba($base-color, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  .step_1 {
    width: 80%;
    height: 80%;
    background-color: rgba($base-color, 0.4);
    opacity: 1.8;
  }
  .step_2 {
    width: 80%;
    height: 80%;
    background-color: $base-color;
  }
}
