@import './../../../../../css/variables';

.quantity_steper {
  .group {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input {
    max-width: 218px;
    min-width: 120px;
    height: 48px;
    position: relative;
    margin: 0 10px;
    border-radius: 4px;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    color: $la-tasa-light-blue;
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  }
}
