@import './../../../css/variables';

.page_title {
  width: 100%;
  padding: $margin;
  box-sizing: border-box;
  background-image: url('/assets/img/header_vectors_desk.svg'),
    linear-gradient(135deg, $orient-blue 0%, $la-tasa-dark-blue 100%);
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: $xs-breakpoint) {
    padding: $margin_small;
    background-image: url('/assets/img/header_vectors_mobile.svg'),
      linear-gradient(135deg, $orient-blue 0%, $la-tasa-dark-blue 100%);
  }
}
