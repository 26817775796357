@import './../../../css/variables';

.public_footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-height: $footer-height;
  font-size: 0.75rem;
}

.copyright {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.items {
  display: flex;
  flex-direction: row;
  min-height: calc(#{$footer-height} - 30px);
  @media screen and (max-width: $xs-breakpoint) {
    max-width: 100%;
    margin: 10px auto;
    padding: 0 10px;
    flex-direction: column;
  }
}

.info {
  margin: auto 0;
  align-items: flex-start;
  text-align: left;
  white-space: nowrap;
  @media screen and (max-width: $xs-breakpoint) {
    text-align: center;
    margin: 10px auto;
  }
  a {
    color: #446cb3;
    text-decoration: underline;
    font-weight: 700;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.links_container {
  @media screen and (max-width: $xs-breakpoint) {
    margin-top: 10px;
  }
}

.social {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 1.2rem;
  label {
    color: $dark-gray;
    font-weight: 500;
    line-height: 1rem;
  }
  a {
    text-decoration: none;
  }

  .logo {
    display: flex;
    border-radius: 3px;
    font-weight: 700;
    height: 1.5rem;
    line-height: 1.5rem;
    width: 100%;
    margin-bottom: 1rem;
    max-width: 150px;
    min-width: 100px;
    .logo_container {
      &.facebook {
        background-color: $orient-blue;
      }
      &.twitter {
        background-color: #00a1df;
      }
    }
    &.facebook {
      border: 1px solid $orient-blue;
      color: $orient-blue;
    }
    &.twitter {
      border: 1px solid #00a1df;
      color: #00a1df;
    }
    .image {
      width: 15px;
      height: 15px;
      padding: 5px;
    }
    .label {
      width: 100%;
      text-align: center;
    }
  }
}

.contact {
  flex-direction: column;
  display: flex;
  justify-content: center;
  @media screen and (max-width: $xs-breakpoint) {
    margin: 10px;
  }
  .contact_item {
    text-align: left;
    color: $dark-gray;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0;
    @media screen and (max-width: $xs-breakpoint) {
      margin: 10px;
    }
    @media screen and (max-width: $xs-breakpoint) {
      display: block;
      text-align: center;
      margin: 10px 0;
    }
    .contact_item_mail {
      text-decoration: none;
      margin: 0;
    }
  }
  .contact_logo {
    width: 20px;
    height: auto;
    margin-right: 1.25rem;
    @media screen and (max-width: $xs-breakpoint) {
      margin: 0 0 0.25rem;
    }
  }
}

hr {
  display: none;
  border-top: 2px solid #dfe8f1;
  border-bottom: 0;
  width: 100%;
  margin: 10px auto;
  @media screen and (max-width: $xs-breakpoint) {
    display: inherit;
  }
}
