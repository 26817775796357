@import '../../../../../../css/variables';

.percentage_selector {
  background-color: $white;
  padding: 1rem;
  align-items: center;
  border: 1px solid $light-gray;
  border-radius: 4px;
  input {
    border: 1px solid $light-gray;
  }
  &.invalid {
    border: 1px solid $error;
  }
}
