@import './../../../../css/variables';

.controls_container {
  display: flex;
  margin-left: -35%;
  width: 100vw;
  @media screen and (max-width: $md-breakpoint) {
    width: 100%;
    margin-left: 0;
    display: inherit;
  }
}
