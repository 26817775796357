@import './../../../../css/variables';

.switch_option {
  min-width: 50px;
  min-height: 40px;
  &:not(:last-child) {
    margin-right: 0.9rem;
  }
  border: 1px solid $light-gray;
  border-radius: 4px;
  transition: all 0.5s ease;
  background-color: $white;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  &.selected {
    box-shadow: $la-tasa-light-blue 0px 0px 0px 2pt;
  }
}
